import {
  MainCore,
  game,
  timeManager,
  CameraTypes,
  CustomEvents,
  THREE,
  TimesTypes,
  AppWSM2021DifficultyTypes,
  AudioManager,
  CameraStates,
  requestManager,
  corePhasesManager,
  settings,
  SettingsTypes,
  gameStats,
  fpsManager,
  cameraManager,
  appWSM2021Config,
  modes,
  GatesBatchingGenerator,
  playersManager,
  CorePhases
} from '@powerplay/core-minigames'
import {
  audioConfig,
  batchingConfig,
  modelsConfig,
  texturesConfig,
  debugConfig,
  cameraConfig,
  gameConfig,
  translatesReplacements
} from './config'
import {
  type SpecialDataFromInit,
  TexturesNames,
  MaterialsNames,
  DisciplinePhases
} from './types'
import { hill } from './Hill'
import { player } from './player'
import { gatesManager } from './GatesManager'
import { inputsManager } from './InputsManager'
import { disciplinePhasesManager } from './phases/DisciplinePhasesManager'
import { appWSM2021AllDifficultiesConfig } from './config/appWSM2021AllDifficultiesConfig'
import { checkpointManager } from './modes/training/CheckpointsManager'
import { materialsConfig } from './config/materialsConfig'
import { trainingTasks } from './modes/training/TrainingTasks'
import { tutorialFlow } from './modes/tutorial/TutorialFlow'
import { pathAssets } from '@/globals/globalvariables'
import type { StartPhaseManager } from './phases/StartPhaseManager'
import { ParticleEffects } from './ParticleEffects'
import * as Sentry from '@sentry/vue'
import {
  debugState,
  directionsState,
  splitTimeState,
  timeState,
  trainingResultsState,
  trainingState,
  tutorialState
} from '@/stores'
import {
  fpsLookerState,
  loadingState
} from '@powerplay/core-minigames-ui'
import { stateManager } from './StateManager'

/**
 * Hlavna trieda pre disciplinu
 */
export class Main {

  /** Hlavna trieda z CORE */
  private mainCore!: MainCore

  // Aktualna pozicia kamery
  private actualCameraPosition = new THREE.Vector3()

  // Aktualna rotacia kamery
  private actualCameraQuaternion = new THREE.Quaternion()

  /** Pause prveho tutorialu */
  private pausedFirstTutorial = false

  /** Partikle Effect */
  private particleEffects!: ParticleEffects

  /** Split data z init requestu */
  private splitData?: number[]

  /**
   * Konstruktor
   */
  public constructor() {

    this.addListeners()
    // pripravenie konfigov pre WSM 2021 - musime kontrolvat takto kvoli typescriptu
    if (modes.isAppWSM2021()) {

      appWSM2021Config.data = appWSM2021AllDifficultiesConfig[
        modes.mode as unknown as AppWSM2021DifficultyTypes // small TS hack :)
      ]

    }

    if (modes.isTutorial()) {

      // nastavime loading pre tutorial
      loadingState().tutorial = {
        isEnabled: true,
        text: 'disciplineName6'
      }

    }

    /*
     * Nastavenie players konfigov, aby sa dobre zoradovali a mali dobre empty vysledky
     * nemusime nic nastavovat, lebo berieme default hodnoty z konfigu
     * this.setPlayersConfig()
     */

    // nastavime penalizaciu
    timeManager.setPenaltySecondsForOne(gameConfig.penaltySeconds)

    // spustime CORE veci a pokial vsetko je v pohode, pusti sa INIT metoda
    this.mainCore = new MainCore(
      {
        meshesCastShadows: materialsConfig[MaterialsNames.skier].meshesArray || [],
        materials: materialsConfig,
        callbacks: {
          inputs: {
            callbackLeft: inputsManager.onKeyLeft,
            callbackRight: inputsManager.onKeyRight,
            callbackUp: inputsManager.onKeyUp,
            callbackDown: inputsManager.onKeyDown,
            callbackAction: inputsManager.onKeyAction,
            callbackAction2: inputsManager.onKeyAction2,
            callbackExit: inputsManager.onKeyExit,
            callbackPrepareVideo: inputsManager.onKeyPrepareVideo
          },
          setSpecialDataFromInitRequest: this.setSpecialDataFromInitRequest,
          createAssets: this.createAssets,
          beforeGameStart: this.beforeGameStart,
          updateBeforePhysics: this.updateBeforePhysics,
          updateAfterPhysics: this.updateAfterPhysics,
          updateAnimations: this.updateAnimations
        },
        batching: batchingConfig,
        debugConfig,
        numberOfAttempts: gameConfig.numberOfAttempts,
        inputSchema: 'giantSlalom'
      },
      translatesReplacements,
      {
        textures: texturesConfig,
        models: modelsConfig,
        audio: audioConfig
      }
    )

    this.initialSetup()

  }

  /**
   * Metoda na overenie a zobrazenie FPS
   */
  private checkFpsRequest(): void {

    if (fpsLookerState().isStarted) {

      const settingsQuality = settings.getSetting(SettingsTypes.quality)
      const fpsData = {
        averageFps: fpsManager.getAverageFpsByQuality(settingsQuality),
        actualFps: fpsManager.getActualFpsByQuality(settingsQuality),
        actualAverageFps: fpsManager.getActualAverageFps()
      }
      fpsLookerState().$patch(fpsData)

    }

  }

  /**
   * Pridanie listenerov
   */
  private addListeners() {

    window.addEventListener(CustomEvents.readyForDisciplineInit, this.init)
    window.addEventListener(CustomEvents.loadingProgress, this.loadingProgress)

  }

  /**
   * Pociatocny setup
   */
  private initialSetup() {

    const localEnv = Number(import.meta.env.VITE_APP_LOCAL) === 1
    this.mainCore.setIsLocalEnv(localEnv)
    game.setIsLocalEnv(localEnv)

    // lokalne si davame ID discipliny, aby sme nemuseli menit v GET parametroch stale
    if (localEnv) requestManager.disciplineID = 6

    // nastavenie verzie zvukov - TODO: dat mozno niekam inam, resp cez konfig?
    AudioManager.PATH_ASSETS = pathAssets
    disciplinePhasesManager.create()

    /*
     * listener na zistenie appky, ze sme v background mode a mame dat pauzu, aby sme setrili
     * prostriedky a aby nehrali zvuky
     */
    window.addEventListener(CustomEvents.toggleBackgroundMode, () => {

      tutorialState().settings = true

    }, false)

  }

  /**
   * Vratenie ignorovanych nazvov textur
   * @returns Pole nazvov ignorovanych textur
   */
  private getIgnoredTexturesNames(): string[] {

    const allRaceTextures = [
      TexturesNames.skierRaceBlackMan,
      TexturesNames.skierRaceBlackWoman,
      TexturesNames.skierRaceMulattoMan,
      TexturesNames.skierRaceMulattoWoman,
      TexturesNames.skierRaceWhiteMan,
      TexturesNames.skierRaceWhiteWoman
    ]

    const usedTextures: string[] = []

    // pridame hraca
    const playerInfo = playersManager.getPlayer()
    usedTextures.push(`${playerInfo.sex}/${TexturesNames.skierRacePrefix}${playerInfo.race}`)

    /*
     * pridame superov, ak su
     * nie su superi
     */

    // vysledok bude rozdiel dvoch poli
    return allRaceTextures.filter(x => !usedTextures.includes(x))

  }

  /**
   * Inicializacia main core
   */
  public init = (): void => {

    gatesManager.setUp(this.splitData ?? [])

    this.mainCore.init(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      this.getIgnoredTexturesNames(),
      TexturesNames.skierRacePrefix
    )
    this.mainCore.setTweenSettingsForStates(cameraConfig.tweenSettingsForCameraStates)
    cameraManager.changeBaseRenderSettings(undefined, 1700)
    trainingTasks.initTraining()
    timeManager.setActive(TimesTypes.total, true)
    // disciplinePhasesManager.phaseManagers[0].preparePhase()

    // UI update
    directionsState().show = true

    if (corePhasesManager.firstInstructions) {

      trainingResultsState().isDisabledPlayAgain = true

    }

  }

  /**
   * Zobrazenie progresu loadingu
   */
  private loadingProgress = (): void => {

    gameStats.setNextLoadingPart()
    loadingState().loadingProgress = gameStats.getLoadingProgress()

  }

  /**
   * Nastavenie specialnych dat z init requestu
   * @param data - Specialne data
   */
  private setSpecialDataFromInitRequest = (data: unknown): void => {

    const specialData = data as SpecialDataFromInit

    this.splitData = specialData?.split ?? []

    if (this.splitData.length <= 0) {

      splitTimeState().noSplitTimes = true

    }

    Sentry.setContext('minigame', {
      id: requestManager.MINIGAME_START_ID,
      idUser: player.uuid
    })

  }

  /**
   * Nastavenie assetov
   */
  private createAssets = (): void => {

    // HILL
    hill.create()

    // HRAC
    let position
    if (gameConfig.skipToFinish.active) position = gameConfig.skipToFinish.position
    player.create(position)

    // BRANKY
    gatesManager.create()

    // CHECKPOINTS
    checkpointManager.create()

    // debug
    this.setUpDebug()

    // Partikle
    this.particleEffects = new ParticleEffects()

  }

  /**
   * puts singletons into window object
   */
  private setUpDebug(): void {

    if (!Number(import.meta.env.VITE_APP_LOCAL)) return

    const debug = {
      gatesManager,
      hill,
      inputsManager,
      player,
      disciplinePhasesManager,
      THREE,
      setVisibityHUD: (visible: boolean) => {

        debugState().isHudActive = visible

      },
      pauseGame: () => {

        if (game.paused) {

          game.resumeGame()

        } else {

          game.pauseGame()

        }

      },
      generateGates: () => {

        GatesBatchingGenerator.generateGiantSlalom(
          { 1: 'Branka0LEFT',
            0: 'Branka1LEFT' },
          { 1: 'Branka0RIGHT',
            0: 'Branka1RIGHT' },
          game.scene.getObjectByName('gates') ?? new THREE.Object3D(),
          new THREE.Vector3(
            gameConfig.startPosition.x,
            gameConfig.startPosition.y,
            gameConfig.startPosition.z
          )
        )

      }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).debug = debug

  }

  /**
   * Nastavenie alebo spustenie veci pred startom hry
   */
  private beforeGameStart = (): void => {

    // nastavime pocuvanie na zaciatok disciplinovej fazy z CORE
    window.addEventListener(
      CustomEvents.startDisciplinePhase,
      disciplinePhasesManager.setStartPhase
    )

    stateManager.setBeforeGameStartPhase()

  }

  /**
   * Spustenie veci v update pred vykonanim fyziky
   */
  private updateBeforePhysics = (): void => {

    player.updateBeforePhysics()
    disciplinePhasesManager.update()
    this.checkFpsRequest()

    gatesManager.update(gatesManager.needsUpdate.triggered)

  }

  /**
   * Spustenie veci v update po vykonani fyziky
   */
  private updateAfterPhysics = (delta: number): void => {

    if (
      !corePhasesManager.isActivePhaseByType(CorePhases.intro) &&
      !corePhasesManager.isActivePhaseByType(CorePhases.discipline)
    ) return
    this.particleEffects.update()

    if (requestManager.isFirstTrainingTutorial() && !this.pausedFirstTutorial) {

      this.pausedFirstTutorial = true
      console.log(requestManager.TUTORIAL_ID)
      game.pauseGame()
      trainingState().firstTutorialMessage = true
      return

    }

    player.updateAfterPhysics(hill.hillMesh)

    this.actualCameraPosition.copy(player.getPosition())
    this.actualCameraQuaternion.copy(player.getQuaternion())

    cameraManager.move(
      this.actualCameraPosition,
      this.actualCameraQuaternion,
      delta,
      [hill.hillMesh],
      cameraConfig.distanceFromGround,
      cameraManager.isThisCameraState(CameraStates.disciplineOutro)
    )

    timeState().$patch({
      timeWithPenalty: timeManager.getGameTimeWithPenaltyInFormat(2),
      time: timeManager.getGameTimeWithPenaltyInSeconds().toString(),
      penaltySeconds: timeManager.getPenaltyInfo().seconds
    })

    const startState = disciplinePhasesManager
      .getDisciplinePhaseManager(DisciplinePhases.start) as StartPhaseManager
    if (modes.isTutorial() && startState.shouldTutorialUpdate) {

      tutorialFlow.update()

    }

  }

  /**
   * Spustenie vykonavania vsetkych animacii
   * @param delta - Delta
   */
  private updateAnimations = (delta: number): void => {

    player.updateAnimations(delta)
    gatesManager.updateAnimations(delta)

  }

  /**
   * Zmena kamery na debug, ak by sme potrebvalo
   */
  public changeCameraToDebug = (): void => {

    cameraManager.setCamera(CameraTypes.debug)

  }

}
